import _ from 'lodash';
import axios from '@/axios.js';
import qs from 'qs';

const state = {
  axies: [],
}

const mutations = {
  SET_AXIES: (state, payload) => {
    state.axies = payload;
  },
  SET_AXIES_AGGREGATED: (state, payload) => {
    state.aggregation = payload;
  },
  SET_TOP_SELLING: (state, payload) => {
    state.topSelling = payload;
  },
  SET_ON_SALE: (state, payload) => {
    state.onsale = payload;
  },
  SET_ON_LISTED: (state, payload) => {
    state.listed = payload;
  },
};

const toFilter = (options) => {
  const bat = _.omitBy({
    $gte: options.dirtyStatsMin >= 0 ? options.dirtyStatsMin : undefined,
    $lte: options.dirtyStatsMax >= 0 ? options.dirtyStatsMax : undefined,
  }, _.isUndefined);

  const soldAt = _.omitBy({
    $gte: options.soldAtMin ? options.soldAtMin.getTime() : undefined,
    $lte: options.soldAtMax ? options.soldAtMax.getTime() : undefined,
  }, _.isUndefined);

  return _.omitBy({
    class: options.class || undefined,
    group: (options.group || options.group === 0) ? options.group : undefined,
    owned: options.owned,
    walletGroup: options.walletGroup,
    'broken.all.total': _.isEmpty(bat) ? undefined : bat,
    'prices.soldAt': soldAt,
    'prices.soldFor': options.soldFor ? { $exists: true } : undefined,
  }, _.isUndefined);
};

const actions = {
  getAxies: ({ commit }, options) => {
    const skip = ((options.page || 1) - 1) * (options.pageSize || 10);

    const filter = toFilter(options);
    const params = _.omitBy({
      skip,
      limit: options.pageSize || 10,
      sortBy: options.sortBy,
      sortDesc: options.sortDesc,
      ...filter,
    }, _.isUndefined);

    return axios.get(`/axies?${qs.stringify(params)}`).then((response) => {
      commit('SET_AXIES', response.data);
      return response.data;
    });
  },
  getAxiesAggregated: ({ commit }, options) => {
    const params = toFilter(options);

    return axios.get(`/axies/aggregated?${qs.stringify(params)}`).then((response) => {
      commit('SET_AXIES_AGGREGATED', response.data);
      return response.data;
    });
  },
  topSelling: ({ commit }, options) => {
    const params = _.omitBy({
      from: (options.from && options.from.getTime) ? options.from.getTime() : options.from,
      to: (options.to && options.to.getTime) ? options.to.getTime() : options.to,
      limit: options.limit,
      number: options.number,
      element: options.element || undefined,
      priceMin: options.priceMin || undefined,
      priceMax: options.priceMax || undefined,
      sortBy: options.sortBy || 'avgPrice',
      sortDesc: options.sortBy ? options.sortDesc : 'true',
    }, _.isUndefined);

    return axios.get(`/${options.game || 'axies'}/top?${qs.stringify(params)}`).then((response) => {
      commit('SET_TOP_SELLING', response.data);
      return response.data;
    });
  },
  onsale: ({ commit }) => {
    return axios.get('/axies/onsale?limit=100').then((response) => {
      commit('SET_ON_SALE', response.data);
      return response.data;
    });
  },
  listed: ({ commit }, options) => {
    console.log(options);

    const params = _.omitBy({
      limit: 25,
      skills: [].concat(options.skills).join(',') || undefined,
      element: options.element || undefined,
      sortBy: options.sortBy || 'market_price',
      sortDesc: options.sortDesc === undefined ?  'false' : options.sortDesc,
    }, _.isUndefined);

    return axios.get(`/${options.game || 'axies'}/listed?${qs.stringify(params)}`).then((response) => {
      commit('SET_ON_LISTED', response.data);
      return response.data;
    });
  },
};

const getters = {};

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}


