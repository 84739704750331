require('dotenv').config();

// axios
import axios from 'axios'
import store from '@/store/store'

const baseURL = process.env.VUE_APP_API_URL || 'https://spl.delta.bet';

const instance = axios.create({
  baseURL,
  withCredentials: false,
})

instance.interceptors.request.use((request) => {
    const token = store.getters.token;
    if (token) {
      request.headers['Authorization'] = `Bearer ${ token }`
    }

    return request;
  }, (error) => {
    console.error(error);
    return Promise.reject(error)
  }
)

export default instance;
