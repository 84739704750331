//import _ from 'lodash';
import axios from '@/axios.js';
import qs from 'qs';
import _ from 'lodash';

const state = {
  tasks: [],
}

const mutations = {
  SET_TASKS: (state, payload) => {
    state.tasks = payload;
  },
};

const actions = {
  list: ({ commit }, options) => {
    const params = _.omitBy({
      refid: options.refid || undefined,
      type: options.type || undefined,
      limit: options.itemsPerPage || 10,
      skip: (options.page - 1) * options.itemsPerPage || undefined,
    }, _.isUndefined);

    return axios.get(`/tasks?${qs.stringify(params)}`).then((response) => {
      commit('SET_TASKS', response.data);
      return response.data;
    });
  },
};

const getters = {};

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

