import _ from 'lodash';
import axios from '@/axios.js';
import qs from 'qs';

const state = {
  cards: [],
  cardStats: [],
}

const mutations = {
  SET_CARDS: (state, payload) => {
    state.cards = payload;
  },
  SET_CARDS_STATS: (state, payload) => {
    state.cardsStats = payload;
  },
  SET_TEAMS: (state, payload) => {
    state.teams = payload;
  },
  SET_TOP100: (state, payload) => {
    state.top100 = payload;
  },
  SET_SUMMONERS: (state, payload) => {
    state.summoners = payload;
  },
  SET_BATTLES: (state, payload) => {
    state.battles = payload;
  },
  SET_PLAYERS: (state, payload) => {
    state.players = payload;
  },
  SET_LANDLORDS: (state, payload) => {
    state.landlords = payload;
  },
  SET_LANDLORDS_STATS: (state, payload) => {
    state.landlordsStats = payload;
  },
};

const toFilter = (options) => {
  return _.omitBy({
    conditions: _.omitBy({
      manacap: options.manacap,
      rules: options.ruleset,
      splinters: (options.allowedColors && options.allowedColors.length) ? options.allowedColors.map(c => {
        return `${c}: Active`;
      }) : undefined,
    }, _.isUndefined),
    prefer: options.focus || undefined,
    progress: options.league ? {
      league: options.league,
    } : undefined,
    combinedColors: options.combinedColors || undefined,
    minbattles: parseInt(options.minbattles, 10),
    summoner: options.summoner || undefined,
    level: parseInt(options.level, 10) || undefined,
    format: options.format || undefined,
    cards: options.cards ? {
      all: options.cards,
    } : undefined,
    topLevel: options.topLevel,
    starter: options.starter,
    match_type: options.match_type,
    top100rating: options.top100rating,
    brawl: options.brawl,
    client: 'web',
    sortBy: [options.sortBy],
    sortDesc: true,
  }, _.isUndefined);
};

const actions = {
  cardsStats: ({ commit }, options) => {
    const filter = toFilter(options);
    const params = _.omitBy({
      limit: options.itemsPerPage,
      skip: (options.page - 1) * options.itemsPerPage,
      sortBy: options.sortBy,
      sortDesc: options.sortDesc,
      ...filter,
      index: parseInt(options.index, 10),
    }, _.isUndefined);

    return axios.post(`/spl/cards/stats`, {
      ...params,
    }).then((response) => {
      commit('SET_CARDS_STATS', response.data);
      return response.data;
    });
  },
  cards: ({ commit }, options) => {
    const params = {
      player: options.player,
    };

    return axios.get(`/spl/cards?${qs.stringify(params)}`, {
      ...params,
    }).then((response) => {
      commit('SET_CARDS', response.data);
      return response.data;
    });
  },
  top100: ({ commit }, options) => {
    //const skip = ((options.page || 1) - 1) * (options.itemsPerPage || 10);

    //const params = _.omitBy({
    //  skip,
    //  limit: options.itemsPerPage || 10,
    //  sortBy: options.sortBy,
    //  sortDesc: options.sortDesc,
    //  teamId: options.teamId,
    //}, _.isUndefined);

    return axios.get(`/spl/teams/top100/${options.teamId}`).then((response) => {
      commit('SET_TOP100', response.data);
      return response.data;
    });
  },

  teams: ({ commit }, options) => {
    const skip = ((options.page || 1) - 1) * (options.itemsPerPage || 10);

    const filter = toFilter(options);
    const params = _.omitBy({
      skip,
      limit: options.itemsPerPage || 10,
      sortBy: options.sortBy,
      sortDesc: options.sortDesc,
      algo: 'topRatio',
      teams: true,
      ...filter,
    }, _.isUndefined);

    return axios.post(`/spl/matches`, {
      ...params,
    }).then((response) => {
      commit('SET_TEAMS', response.data);
      return response.data;
    });
  },
  summoners: ({ commit }) => {
    return axios.get('/spl/cards/summoners').then(response => {
      commit('SET_SUMMONERS', response.data)
      return response.data;
    });
  },
  battles: ({ commit }, options) => {
    const params = {
      player: options.player,
      timestamp: options.timestamp,
      limit: options.itemsPerPage,
      skip: (options.page - 1) * options.itemsPerPage,
    };

    return axios.get(`/spl/battles?${qs.stringify(params)}`).then(response => {
      commit('SET_BATTLES', response.data)
      return response.data;
    });
  },
  players: ({ commit }, options) => {
    const params = _.omitBy({
      limit: options.itemsPerPage,
      skip: (options.page - 1) * options.itemsPerPage,
      id: options.name || undefined,
      tag: options.tag || undefined,
    }, _.isUndefined);

    return axios.get(`/spl/players?${qs.stringify(params)}`).then(response => {
      commit('SET_PLAYERS', response.data)
      return response.data;
    });
  },
  landlordsStats: ({ commit }, options) => {
    const skip = ((options.page || 1) - 1) * (options.itemsPerPage || 10);
    const filter = options;
    const params = _.omitBy({
      skip,
      limit: options.itemsPerPage || 10,
      sortBy: options.sortBy,
      sortDesc: options.sortDesc,
      ...filter,
    }, _.isUndefined);


    return axios.get(`/spl/rentals/statistics?${qs.stringify(params)}`).then((response) => {
      commit('SET_LANDLORDS_STATS', response.data);
      return response.data;
    });
  },

  landlords: ({ commit }) => {
    return axios.get(`/spl/landlords`).then((response) => {
      commit('SET_LANDLORDS', response.data);
      return response.data;
    });
  },
};

const getters = {};

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

