import _ from 'lodash';
import axios from '@/axios.js';
import qs from 'qs';

const state = {
    proxies: [],
}

const mutations = {
    SET_REQUESTS: (state, payload) => {
        state.requests = payload;
    },
};

const actions = {
    list: ({ commit }, options) => {
        const skip = ((options.page || 1) - 1) * (options.itemsPerPage || 10);
        const filter = options;
        const params = _.omitBy({
            skip,
            limit: options.itemsPerPage || 10,
            sortBy: options.sortBy,
            sortDesc: options.sortDesc,
            ...filter,
        }, _.isUndefined);

        return axios.get(`/requests?${qs.stringify(params)}`).then((response) => {
            commit('SET_REQUESTS', response.data);
            return response.data;
        });
    },
};

const getters = {};

export default {
    isRegistered: false,
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

