import qs from 'qs';
import _ from 'lodash';
import axios from '@/axios.js';

const state = {
  activities: [],
}

const mutations = {
  SET_ACTIVITIES: (state, payload) => {
    state.activities = payload;
  },
  SET_ACTIVITY: (state, payload) => {
    state.activity = payload;
  },
  SET_EXECUTIONS: (state, payload) => {
    state.executions = payload;
  },
  SET_WORKERS: (state, payload) => {
    state.workers = payload;
  },
};

const actions = {
  activities: ({ commit }, options) => {
    const skip = ((options.page || 1) - 1) * (options.itemsPerPage || 10);
    const filter = options;
    const params = _.omitBy({
      skip,
      limit: options.itemsPerPage || 10,
      sortBy: options.sortBy,
      sortDesc: options.sortDesc,
      ...filter,
    }, _.isUndefined);

    return axios.get(`/protectors/activities?${qs.stringify(params)}`)
      .then((response) => {
      commit('SET_ACTIVITIES', response.data);
      return response.data;
    });
  },
  activity: ({ commit }, id) => {
    const params = {};

    return axios.get(`/protectors/activities/${id}?${qs.stringify(params)}`)
      .then((response) => {
      commit('SET_ACTIVITY', response.data);
      return response.data;
    });
  },
  updateActivity: ({ commit }, item) => {
    const params = {};

    return axios.put(`/protectors/activities/${item._id}?${qs.stringify(params)}`, {
      item,
    })
      .then((response) => {
      commit('SET_ACTIVITY', response.data);
      return response.data;
    });
  },
  executions: ({ commit }, options) => {
    const skip = ((options.page || 1) - 1) * (options.itemsPerPage || 10);
    const filter = options;
    const params = _.omitBy({
      skip,
      limit: options.itemsPerPage || 10,
      sortBy: options.sortBy,
      sortDesc: options.sortDesc,
      ...filter,
    }, _.isUndefined);

    return axios.get(`/protectors/activities/executions?${qs.stringify(params)}`)
      .then((response) => {
      commit('SET_EXECUTIONS', response.data);
      return response.data;
    });
  },
  sendCommand: (c, { account, command }) => {
    return axios.post(`/accounts/${account}/command`, {
      service: 'protectors',
      command,
    })
      .then((response) => {
        return response.data;
      });
  },
  getWorkers: ({ commit }) => {
    return axios.get('/protectors/activities/executions/workers').then((response) => {
      commit('SET_WORKERS', response.data);
      return response.data;
    });
  },
};

const getters = {};

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

