import Vue from "vue";
import Vuex from "vuex";
//import router from '../router'
import axios from '../axios';
import _ from 'lodash';
import axies from './axies';
import spl from './spl';
import users from './users';
import proxies from './proxies';
import requests from './requests';
import accounts from './accounts';
import protectors from './protectors';
import pixels from './pixels';
import atlas from './atlas';
import tasks from './tasks';
import claims from './claims';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "primary",
    setHorizontalLayout: false, // Horizontal layout
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    SET_USER(state, data) {
      state.user = data;
      console.log('user state is set');
    },
    SET_TOKEN(state, data) {
      state.token = data;
      console.log('token state is set');
    },
    logout(state) {
      state.user = null;
      state.token = null;
    },
    SET_ACCOUNTS(state, data) {
      state.accounts = data;
    },
    SET_WALLETS(state, data) {
      state.wallets = data;
    },
  },
  actions: {
    async login({ commit }, user) {
      return axios.post('/users/tokens', {
        ...user,
        type: 'auth'
      }).then(async response => {
        if (!response.data.errors) {
          const user = _.get(response, 'data.included[0].attributes');
          const token = _.get(response, 'data.data[0].attributes.id');

          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          await commit('SET_TOKEN', token);
          await commit('SET_USER', user);
          return new Promise(resolve => setTimeout(() => resolve(response.data), 500));
        }
        return response.data;
      });
    },
    async logout({ commit }) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return commit('logout');
    },
    async getAccounts({ commit }) {
      return axios.get('/users/me/accounts').then(async response => {
        await commit('SET_ACCOUNTS', response.data.accounts);
        return response.data;
      });
    },
    async getWallets({ commit }) {
      return axios.get('/users/me/wallets').then(async response => {
        await commit('SET_WALLETS', response.data.wallets);
        return response.data;
      });
    },
  },
  getters: {
    isAuthenticated: (state, getters) => !!getters.token,
    token: (state) => state.token || localStorage.getItem('token'),
    user: (state) => state.user || localStorage.getItem('user'),
  },
  modules: {
    axies,
    spl,
    users,
    proxies,
    requests,
    tasks,
    accounts,
    protectors,
    atlas,
    pixels,
    claims,
  },
});
