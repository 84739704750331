import _ from 'lodash';
import axios from '@/axios.js';
import qs from 'qs';

const state = {
  accounts: [],
}

const mutations = {
  SET_ACCOUNTS: (state, payload) => {
    state.accounts = payload;
  },
};

const actions = {
  list: ({ commit }, options) => {
    const params = _.omitBy({
      limit: options.itemsPerPage,
      skip: (options.page - 1) * options.itemsPerPage,
      id: options.name,
      hasPerson: options.hasPerson,
      autocomplete: options.autocomplete,
      sort: options.sortBy,
      due: options.due,
      service: options.service,
      tags: _.isArray(options.tags) ? options.tags.join(',') : options.tags,
    }, _.isUndefined);

    return axios.get(`/accounts?${qs.stringify(params)}`).then((response) => {
      commit('SET_ACCOUNTS', response.data);
      return response.data;
    });
  },
};

const getters = {};

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

