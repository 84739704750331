import _ from 'lodash';
import axios from '@/axios.js';
import qs from 'qs';

const state = {
    claims: [],
}

const mutations = {
    SET_CLAIMS: (state, payload) => {
        state.claims = payload;
    },
};

const actions = {
    list: ({ commit }, options) => {
        const params = _.omitBy({
            limit: options.itemsPerPage,
            skip: (options.page - 1) * options.itemsPerPage,
            name: options.name,
            sort: options.sortBy,
            service: options.service,
        }, _.isUndefined);

        return axios.get(`/claims?${qs.stringify(params)}`).then((response) => {
            commit('SET_CLAIMS', response.data);
            return response.data;
        });
    },
};

const getters = {};

export default {
    isRegistered: false,
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

