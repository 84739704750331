import qs from 'qs';
import _ from 'lodash';
import axios from '@/axios.js';

const state = {
    matches: [],
    activities: [],
}

const mutations = {
    SET_MATCHES: (state, payload) => {
        state.matches = payload;
    },
    SET_WORKERS: (state, payload) => {
        state.workers = payload;
    },
    SET_ACTIVITIES: (state, payload) => {
        state.activities = payload;
    },
    SET_ACTIVITY: (state, payload) => {
        state.activity = payload;
    },
};

const actions = {
    matches: ({ commit }, options) => {
        const skip = ((options.page || 1) - 1) * (options.itemsPerPage || 10);
        const filter = options;
        const params = _.omitBy({
            skip,
            limit: options.itemsPerPage || 10,
            sortBy: options.sortBy,
            sortDesc: options.sortDesc,
            ...filter,
        }, _.isUndefined);

        return axios.get(`/pixels/matches?${qs.stringify(params)}`)
            .then((response) => {
                commit('SET_MATCHES', response.data);
                return response.data;
            });
    },
    getWorkers: ({ commit }) => {
        return axios.get('/pixels/matches/workers').then((response) => {
            commit('SET_WORKERS', response.data);
            return response.data;
        });
    },
    sendCommand: (c, { account, command }) => {
        return axios.post(`/accounts/${account}/command`, {
            service: 'protectors',
            command,
        })
            .then((response) => {
                return response.data;
            });
    },
    activities: ({ commit }, options) => {
        const skip = ((options.page || 1) - 1) * (options.itemsPerPage || 10);
        const filter = options;
        const params = _.omitBy({
            skip,
            limit: options.itemsPerPage || 10,
            sortBy: options.sortBy,
            sortDesc: options.sortDesc,
            ...filter,
        }, _.isUndefined);

        return axios.get(`/pixels/activities?${qs.stringify(params)}`)
            .then((response) => {
                commit('SET_ACTIVITIES', response.data);
                return response.data;
            });
    },
    activity: ({ commit }, id) => {
        const params = {};

        return axios.get(`/pixels/activities/${id}?${qs.stringify(params)}`)
            .then((response) => {
                commit('SET_ACTIVITY', response.data);
                return response.data;
            });
    },
    updateActivity: ({ commit }, item) => {
        const params = {};

        return axios.put(`/pixels/activities/${item._id}?${qs.stringify(params)}`, {
            item,
        })
            .then((response) => {
                commit('SET_ACTIVITY', response.data);
                return response.data;
            });
    },
};

const getters = {};

export default {
    isRegistered: false,
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

