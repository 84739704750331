//import _ from 'lodash';
import axios from '@/axios.js';
import qs from 'qs';

const state = {
  users: [],
}

const mutations = {
  SET_USERS: (state, payload) => {
    state.users = payload;
  },
};

const actions = {
  list: ({ commit }, options) => {
    const params = {
      limit: options.itemsPerPage,
      skip: (options.page - 1) * options.itemsPerPage,
    };

    return axios.get(`/users?${qs.stringify(params)}`).then((response) => {
      commit('SET_USERS', response.data);
      return response.data;
    });
  },
};

const getters = {};

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

